import {Render} from "nano/nano.jsx";
import {computed, ref} from "nano/reactive.jsx";
import {sha256} from "nano/lib/security.js";
import './Signup.css'
import Show from 'nano/Show.jsx'
import api from '../api.jsx'

export default function Signup(props, children) {
    const {type, on_login_success} = props;

    const email = ref()
    const name = ref();
    const password = ref();

    // const email = ref("eyonyan@gmail.com")
    // const name = ref("snow");
    // const password = ref("Handi0113");

    const button_label = ref("Continue");
    const disabled = computed(() => {
        return !name.value || !password.value;
    });

    async function onkeydown(event) {
        if (event.keyCode === 13) {
            await onok(event);
        }
    }

    function next_focus(id) {
        return function (event) {
            if (event.keyCode === 13) {
                const $el = document.getElementById(id);
                $el.focus();
            }
        };
    }

    async function onok(event) {
        event.preventDefault()
        event.stopPropagation()
        if (disabled.value) {

        } else {
            const password_hash = await sha256(password.value);
            const message = {
                token: localStorage.getItem("token") || "",
                username: name.value,
                password_hash
            };
            const response = await api.post("/api/login", message);
            console.log(response);
            if (response.ok) {
                localStorage.setItem("token", response.token);
                localStorage.setItem("password_hash", password_hash);
                localStorage.setItem("username", name.value);

                // post token to browser extension
                window.postMessage({from: "translingo", token: response.token});
                onclose(event);
                on_login_success(response)
            } else {
                button_label.value = "Wrong name or password";
                password.value = "";
            }
        }
    }

    const style = {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "20px",
        backgroundColor: 'hsla(220, 30%, 10%, 1)',
    }

    const email_pattern = `\\S+@\\S+\\.\\S+`
    const dialog = Render(() => (
        <dialog id="login"
                class="dialog"
                onclose={onclose}
                style={style}>
            <div class="dialog_content" onclick={onclick_content}>
                <section class="section section_title">
                    <h2 style={{margin: "auto", "padding-left": "7px"}}>{type}</h2>
                </section>

                <section class="section section_input">

                    <input type="email" placeholder="Email" model={email} class="input"
                            pattern={email_pattern}
                           name="email"
                           autocomplete="email"
                           onkeydown={next_focus("password")}></input>
                </section>
                <section class="section section_input">

                    <input type="text" placeholder="Name" model={name} class="input"
                            name="name"
                           autocomplete="current-paname"
                           onkeydown={next_focus("password")}></input>
                </section>
                <section class="section section_input">

                    <input type="password" placeholder="Password" model={password} class="input" id="password"
                           pattern=".{7,}"
                           name="password"
                           autocomplete="current-password"
                           onkeydown={onkeydown}></input>
                    <Show>
                        <div>At least 7 characters</div>
                    </Show>
                </section>
                <section class="section section_button">
                        <div>
                            <button class="button"
                                    disabled={disabled} onclick={onok}>
                                <span>{button_label}</span>
                            </button>
                        </div>
                        {/*<div style={{display: "flex", "justify-content": "space-between", "margin": "10px 0"}}>*/}
                        {/*    <p>Not a member yet</p>*/}
                        {/*    <p>Signup</p>*/}
                        {/*</div>*/}
                </section>
            </div>
        </dialog>
    ));

    function onclick_content(event) {
        event.stopPropagation();
    }

    function onclick_dialog(event) {

        console.log(event);
        const {target} = event;
        if (target === dialog) {
            dialog.close();
        } else {
            //
        }
    }

    function onclose(event) {
        console.log(event);

        dialog.remove();
    }

    // const portal = document.getElementById("portal");
    // portal.appendChild(dialog);
    document.body.appendChild(dialog);
    dialog.showModal();
}