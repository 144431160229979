import {per_page_words, word_match_batch_size} from "../config.js";
import Options from "../page/Options.jsx";
import MatchWordPage from "./MatchWordPage.jsx";
import {algorithm, basket, batch, current_progress, next_progress, theme} from "../store.jsx";
import {ref_render} from "nano/nano.jsx";

console.log({type: "tracer"})

let match_index = 0

export default function MatchAlgorithm() {

    let option = false
    const me = {
        // update,
        get_generator,
        finished: false}
    let match_times = 0

    const navigate = {
        right: 'MatchWord',
        next: 'MatchWord'
    }

    async function get_generator(/* $current_dom, */$dom) {
        const words = [...batch.value]
        const props = {words}
        next_progress.value = next_progress.value + 1
        const progress = next_progress.value

        match_times = match_times + 1
        if (match_times >= 3) {
            me.finished = true
            batch.value = []
            match_times = 0
        }

        const name = words.map(word => word.text).join(',')
        return {name, generator: match_generator}
        async function match_generator($dom) {
            const pre_hook = () => {
                theme.value = 'light'
                algorithm.value.swipe.use(true)
                current_progress.value = progress
            }
            const post_hook = () => {
            }

            const contexts = {props, pre_hook, post_hook, save_history: false, navigate,
                dataset: {
                    // word: word.text,
                    component: 'Word'
                }}

            await ref_render($dom, MatchWordPage, contexts)
            return false
        }
    }

    async function update(/* $current_dom, */$dom) {
        const words = batch.value
        const props = {words}
        const pre_hook = () => {
            theme.value = 'light'
            algorithm.value.swipe.use(true)
            match_times = match_times + 1
            if (match_times >= 3) {
                me.finished = true
                batch.value = []
                match_times = 0
            }
        }
        const post_hook = () => {
            // match_times = match_times + 1
            // console.log(match_index)
            // current_progress.value = current_progress.value + 1
            // me.finished = match_index % word_match_batch_size === 0
        }

        const contexts = {props, pre_hook, post_hook, save_history: false, navigate}

        await ref_render($dom, MatchWordPage, contexts)
    }
    return me
}
