import For from "nano/For.jsx";
import Show from "nano/Show.jsx";
import "./Word.css";
import {get_lang_flag} from "nano/lib/unicode.js";
import {language_names} from "../../common/language.js";
import {play, playByText} from "../audio.js";
import {adjust_element_size} from 'nano/dom.jsx'
import {computed, on_mounted, ref} from 'nano/reactive.jsx'
import api from '../api.jsx'
import IconTrash2 from "solidjs-feather/IconTrash2.jsx";
import IconRefreshCw from "solidjs-feather/IconRefreshCw.jsx";
import IconArrowUp from "solidjs-feather/IconArrowUp.jsx";

import {algorithm, basket, batch} from "../store.jsx";
import Header from "../components/Header.jsx";

const NAME = "ReviewWord";
console.log(NAME, {type: "tracer"});

export default function ReviewWordPage(props = {}, children = []) {
    const {
        word = {
            id: 23,
            language: 'english',
            translations: {
                "english": "Hello",
                "chinese": "你好",
                "swedish": "Hej Hej",
            }
        },
        grid= 3 * 3 - 1,
        progress,
        total,
        word_index,
    } = props;
    const {detail = {}} = word

    const image = ref(word.images?.at && word.images?.at(0))
    const has_image = computed(() => {
        const filename = image?.value?.filename || image.value || ''
        return filename && !filename.includes('no_image')
    })
    let all_images = undefined
    const show_refresh = ref(false)
    let index = 0
    const images = ref([])
    function refresh(event) {
        const imgs = all_images.slice(Math.min(all_images.length - grid, index) , index+grid);
        if (imgs.length > 0) {
            images.value = [...imgs, '/no_image.png']
        }
        index = (index + grid ) % all_images.length
        // if (index > all_images.length) {
        //     show_refresh.value = false
        // }
    }

    on_mounted(async () => {
        if (image.value) {

        } else {
            try {
                const resp = await api.get("/api/pixabay", {text: word.english});
                all_images = resp.hits.map(hit => hit.webformatURL);
                const imgs = all_images.slice(0, grid);
                if (imgs.length > 0) {
                    images.value = [...imgs, '/no_image.png']
                }
                index = grid;
                show_refresh.value = all_images.length > grid;
                // images.value = hits.map(hit => hit.webformatURL)
                console.log(images.value);

            } catch (error) {
                console.error(error, url);
            }
        }
    })
    // const img = `/images/${word.english.replaceAll(' ', '_')}.jpg`
    // const style = `background-image: url(/images/${word.english.replaceAll(' ', '_')}.jpg)`

    // there are more items than translation
    const items = Object.entries(word).filter(([lang, text]) => language_names.includes(lang))

    if (detail.pronunciation) {

    }

    async function set_image(event, url, i) {
        if (i === images.value.length - 1) {
            image.value = await api.post('/api/translation/image', {id: word.id, name: 'no_image.jpg', text: word.english})
            show_refresh.value = false
        } else {
            image.value = await api.post('/api/translation/image', {id: word.id, url, text: word.english})
            show_refresh.value = false
        }
    }

    const $grid = (
        <div class="grid_3x3" style="width: 100%; height: 300px; gap: 2px">
            <For _each={images}>{(image) => {
                return (
                    <div class="image_container" onclick={(event) => set_image(event, image)}>
                        <img src={image} ></img>
                    </div>)
            }}</For>
        </div>)

    async function remove(event) {
        basket.value.splice(word_index, 1)
        algorithm.value.remove(event)
        batch.pop()
        console.log(batch)

        await api.delete('/api/basket/', {id: word.id, text: word.text, language: word.language})
    }

    async function on_continue(event) {
        algorithm.value.onswipeup(event)
    }

    return (
        <div class="page with_header_footer" >
            {/*<div class="header">*/}
            {/*    /!*<div><progress value={progress} max={total}></progress></div>*!/*/}
            {/*    <h3>REVIEW WORD</h3>*/}
            {/*    <div>Tap to speak</div>*/}
            {/*</div>*/}
            {/*<Header/>*/}
            <Show when={has_image} description={`image-${word.text}`}>
                <div class="image_container" style="width: 100%; height: 300px">
                <img src={computed(() => image.value?.filename && `/images/${image.value?.filename}`)}></img>
                </div>
            </Show>

            <Show when={computed(() => !image.value && images.value.length > 0)}>
                <div>
                <div class="grid_3x3" style="width: 100%; height: 300px; gap: 2px">
                    <For _each={images}>{(image, i) => {
                        return (
                            <div class="image_container" onclick={(event) => set_image(event, image, i)}>
                                <img src={image} ></img>
                            </div>)
                    }}</For>
                </div>
                    <div style="display: flex; justify-content: center; padding-top: 10px;">
                        <Show when={show_refresh}>
                            <span onclick={refresh} class="icon_label">
                                <IconRefreshCw/>
                                {/*<span>Change Batch</span>*/}
                            </span>
                        </Show>
                    </div>
                </div>
            </Show>

            <div class="review_word" >
                <For _each={items}>{(item, index) => {
                    const [language, text] = item;
                    const flag = `${get_lang_flag(language)} `;
                    function onclick(event) {
                        play(text, language)
                        // playByText(language, text)
                    }
                    return (
                        <div onclick={onclick}>
                            <span>{flag}</span>
                            <span on_mounted={adjust_element_size({max_font:30})}>{text}</span>
                        </div>);
                }}</For>
            </div>

            <div class="footer">
                <button class="continue_button" style="flex: 1; "
                        onclick={on_continue}>
                    <span style="font-size: 1.3rem; position: relative; top: 3px;"><IconArrowUp/></span>
                    <span>CONTINUE</span>
                </button>
                <button style="border: none; outline: none; padding: 10px; font-size: 1em; background: none;"
                        onclick={remove} ><IconTrash2/></button>
            </div>

        </div>
    );
}