import {ref} from "nano/reactive.jsx";
import {position} from "./store.jsx";
import {clamp} from 'nano/lib/_.js'
import {Render} from "nano/nano.jsx";

// https://youtu.be/bV9idMbioqg
console.log({type: "tracer"});
export default function UseSwipe(
    {
        onswipeup,
        onswipedown,
        onswipeleft,
        onswiperight,
        // when trigger onswipe event, 10% of window height or 100px
        threshold = Math.max(window.innerHeight / 10, 100),
        // when to determine direction, 3px, either x or y over this threshold
        direction_threshold = 3,
        // when to determine direction, x/y > 2 or y/x > 2
        direction_ratio = 2,
        // only track location while not fire onswipe event
        swipe = true,
        // when the current dom bigger than window height
        // it needs to be first finish scroll that part first
        $current_dom,
        watch= localStorage.getItem('watch_use_swipe')
    } = {},
    ) {

    console.log('UseSwipe()')
    let $element

    const startX = ref()
    const startY = ref()
    let shiftX = ref(0);
    let shiftY = ref(0);
    let shiftYExcludeScroll = ref(0)
    let scrollShift = ref(0)

    let $clientX = ref()
    let $clientY = ref()
    let event_added = false // guard to only add touchstart event once
    const me = {
        use,
        swipe,
        directions: {
            left: true,
            right: true,
            up: true,
            down: true,
        },
        shiftX,
        shiftY,
        scrollShift,
        shiftYExcludeScroll,
    };

    let not_fired = true;
    let direction = undefined;

    let fingers;

    function ontouchstart(event) {
        // event.preventDefault();

        const touches = [...event.touches];
        fingers = touches.length;
        startX.value = ~~touches[0].clientX;
        startY.value = ~~touches[0].clientY;
        direction = undefined
        console.log('touchstart', startX.value, startY.value);

        not_fired = true;

        scrollShift.value = $current_dom.value.scrollHeight - window.innerHeight
        shiftX.value = 0;
        shiftY.value = 0;
        scrollShift.value = 0
        shiftYExcludeScroll.value = 0
    }

    function ontouchmove(event) {
        const touches = [...event.touches];
        $clientX.value = touches[0].clientX;
        $clientY.value = touches[0].clientY;
        // UseSwipe.jsx:92 [Intervention] Unable to preventDefault inside passive event listener due to target being treated as passive.
        // even ontouchmove is not added with {passive: true},
        // ontouchstart has  {passive: true} will treat it "window" as passive as well.
        // event.preventDefault();
        const _shiftX = ~~($clientX.value - startX.value);
        const _shiftY = ~~($clientY.value - startY.value);

        if (me.swipe) {
            const {scrollHeight, scrollTop} = $current_dom.value || {}// .scrollHeight <= (window.innerHeight + $current_dom.value.scrollTop)
            const height = window.innerHeight + scrollTop + 1


            if (direction === 'y') {
                shiftX.value = 0;

                if (me.directions.up && _shiftY < 0) {
                    shiftY.value = Math.min(_shiftY, window.innerHeight);
                    shiftYExcludeScroll.value = Math.min(0, _shiftY + scrollShift.value)
                } else if (me.directions.down && _shiftY> 0) {
                    shiftY.value = Math.max(_shiftY, -window.innerHeight);
                    shiftYExcludeScroll.value = Math.max(0, _shiftY + scrollShift.value)
                }
            } else if (direction === 'x') {
                shiftY.value = 0;

                if (position.value === 'middle') {
                    shiftX.value = clamp(_shiftX, -window.innerWidth, window.innerWidth);
                } else if (position.value === 'left') {
                    shiftX.value = Math.min(_shiftX, window.innerWidth);
                } else if (position.value === 'right') {
                    shiftX.value = Math.max(_shiftX, -window.innerWidth);
                }
            } else {
                const aShiftX = Math.abs(_shiftX);
                const aShiftY = Math.abs(_shiftY);
                const over_threshold = aShiftX > direction_threshold || aShiftY > direction_threshold;
                const ratio = aShiftY / aShiftX
                // console.log(aShiftX, aShiftY, over_threshold, ratio)
                if (over_threshold) {
                    if (ratio > direction_ratio) {
                        direction = "y";

                    } else if (aShiftX / aShiftY > direction_ratio) {
                        direction = "x";
                    }
                }
            }
            //
            // console.log({
            //     direction,
            //     ...me.directions,
            //     clientX: $clientX.value,
            //     clientY: $clientY.value,
            //     startX: startX.value,
            //     startY: startY.value,
            //     shiftX: shiftX.value,
            //     shiftY: shiftY.value,
            //     _shiftX,
            //     _shiftY,
            //     dom: $current_dom.value,
            //     scrollHeight,
            //     height
            // });
        }
    }

    function ontouchend(event) {
        if (me.swipe) {
            if (shiftY.value < -threshold && not_fired && onswipeup) {
                // console.log({onswipeup: true, clientX, clientY, startX, startY, shiftX: shiftX.value, shiftY: shiftY.value});
                onswipeup({shiftX: shiftX.value, shiftY: shiftY.value, fingers});
                not_fired = false;
            }
            if (shiftY.value > threshold && not_fired && onswipedown) {
                // console.log({onswipedown: true, clientX, clientY, startX, startY, shiftX: shiftX.value, shiftY: shiftY.value});
                onswipedown({shiftX: shiftX.value, shiftY: shiftY.value, fingers});
                not_fired = false;
            }
            if (shiftX.value < -threshold && not_fired && onswipeleft) {
                // console.log({onswipeleft: true, clientX, clientY, startX, startY, shiftX: shiftX.value, shiftY: shiftY.value});
                onswipeleft({shiftX: shiftX.value, shiftY: shiftY.value, fingers});
            }
            if (shiftX.value > threshold && not_fired && onswiperight) {
                // console.log({onswipeleft: true, clientX, clientY, startX, startY, shiftX: shiftX.value, shiftY: shiftY.value});
                onswiperight({shiftX: shiftX.value, shiftY: shiftY.value, fingers});
            }

        }

        startX.value = undefined;
        startY.value = undefined;
        $clientX.value = undefined
        $clientY.value = undefined

        shiftX.value = 0;
        shiftY.value = 0;
        scrollShift.value = 0
        shiftYExcludeScroll.value = 0

        direction = undefined;
        // document.body.style.setProperty("--shiftX", "0px");
        // document.body.style.setProperty("--shiftY", "0px");
    }

    function use(_swipe=true, _$clientX = ref(), _$clientY = ref()) {
        console.log('useSwipe.use ', event_added, Date.now())
        me.swipe = _swipe
        $clientX = _$clientX
        $clientY = _$clientY
        if (event_added) {
            console.log('already listening ', window.ontouchstart)
        } else {
            console.log('add ontouchstart listening ', window.ontouchstart)
            window.addEventListener('touchstart', ontouchstart, {passive: true})
            window.addEventListener('touchmove', ontouchmove, {passive: true})
            window.addEventListener('touchcancel', ontouchend, {passive: true})
            window.addEventListener('touchend', ontouchend, {passive: true})
            event_added = true
            if (watch) {
                $element = Render(() => {
                        return (
                            <div style="position: fixed; top: 100px; left: 10px; display: flex; flex-direction: column;">
                                <div><span>startX: </span><span>{startX}</span></div>
                                <div><span>startY: </span><span>{startY}</span></div>
                                <div><span>clientX: </span><span>{$clientX}</span></div>
                                <div><span>clientY: </span><span>{$clientY}</span></div>
                                <div><span>shiftX: </span><span>{shiftX}</span></div>
                                <div><span>shiftY: </span><span>{shiftY}</span></div>
                                <div><span>shiftScrollY: </span><span>{shiftYExcludeScroll}</span></div>
                                <div style="position: fixed; top: 350px; left: 50%; transform: translate(-50%, -50%)">350</div>
                                <div style="position: fixed; top: 450px; left: 50%; transform: translate(-50%, -50%)">450</div>
                            </div>)
                    })
                document.body.appendChild($element)
            }
            // $element.ontouchstart = ontouchstart
        }
    }


    //
    // function use(enable) {
    //     $element.ontouchstart = enable && ontouchstart;
    // }

    // use({
    //     touch,
    //     swipe,
    //     mouse
    // })

    return me;
}