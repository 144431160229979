import {per_game_words, per_page_words, word_match_batch_size} from "../config.js";
import {algorithm, basket, current_progress, header, theme} from "../store.jsx";
import {ref_render} from "nano/nano.jsx";
import Game from './Game.jsx'

console.log({type: "tracer"})

let game_index = 0

export default function GameAlgorithm(page) {

    let prev_header
    const me = {get_generator, finished: true}
    //
    // async function init($current) {
    //     return await update_dom($current)
    // }
    //
    // async function update_dom($dom, page, hook, ) {
    //     $dom.value = await async_render(page)
    //     $dom.value.hook = hook
    //     $dom.value.navigate = navigate
    // }
    //
    const navigate = {
        right: 'Game',
        next: 'Game'
    }

    function get_generator() {
        let initial_run = true
        const words = basket.value.slice(game_index, game_index + per_game_words)
        const props = {words, pointer: algorithm.pointer}

        return {name: 'game', generator: game_generator}
        async function game_generator($dom) {
            const post_hook = () => {
                if (initial_run) {
                    game_index = game_index + per_game_words
                }
                me.finished = true
                header.value = prev_header
                algorithm.value.swipe.use(true)
            }
            const pre_hook = () => {
                prev_header = header.value
                header.value = false
                theme.value = 'dark'
                algorithm.value.swipe.use(false)
                current_progress.value = 1
            }

            const contexts = {props, post_hook, pre_hook, save_history: false, navigate}

            await ref_render($dom, Game, contexts)
            return false
        }
    }
    return me
}
