import {computed, ref} from "nano/reactive.jsx";
import {IndexedDB} from "nano/lib/IndexedDB.js";
import Translator from "./page/Translator.jsx";

import {base64_to_object} from 'nano/lib/converter.js'
import api from "./api.jsx";
import Options from "./page/Options.jsx";
import {number_matches, word_match_batch_size, word_review_batch_size} from "./config.js";
import {use_visibility} from 'nano/lib/use.js'


const NAME = 'store'
console.log({type: 'tracer'})
export const deferred_install = ref();
export const installed_type = ref("browser");
export const offline = ref()
const $token = ref()
export const fluent_language = ref('english')

const default_setting = {
    languages: ["english", "chinese"],
    selected_languages: ["english", "chinese"],
    mother_tongues: ["chinese"],
    fluent_languages: ['english'],
    learning_languages: ["english"]
}

const modes = {
    Music: {
        ...default_setting,
        pages: [
            {name: 'Music', data: []}
        ]
    },
    Story: {
        ...default_setting,
        pages: [
            {
                name: "Story", data:
                    [
                        {title: "长袜子皮皮1-6", audio: "/stories/Pipi1-6.m4a", transcript: "/stories/Pipi1-6.json"},
                        {title: "长袜子皮皮7-9", audio: "/stories/pipi7-9.m4a", transcript: "/stories/pipi7-9.json"},
                        {title: "长袜子皮皮10-13", audio: "/stories/pip10-13.m4a", transcript: "/stories/pip10-13.json"},
                        {title: "长袜子皮皮14-17", audio: "/stories/Pipi14-17.m4a", transcript: "/stories/Pipi14-17.json"},
                        {title: "长袜子皮皮18-23", audio: "/stories/pip18-23.m4a", transcript: "/stories/pip18-23.json"},
                        {title: "长袜子皮皮24-31", audio: "/stories/pipi24-31.m4a", transcript: "/stories/pipi24-31.json"},
                    ]
            }
        ]
    },
    Vocabulary: {
        ...default_setting,
        pages: [
            {name: "Vocabulary", data: []}
        ]
    },
    Recorder: {
        ...default_setting,
        pages: [
            {name: 'Recorder', data: []}
        ]
    },
    Editor: {
        ...default_setting,
        pages: [
            {name: 'Editor', data: []}
        ]
    },
    Game: {
        ...default_setting,
        pages: [
            {name: 'Game', data: []}
        ]
    },
    Heritage: {
        ...default_setting,
        pages: [
            {name: 'Heritage', data: []}
        ]
    }
}

export const app = 'translingo'
// export const app = 'familearn'
export const mode = undefined
// export const mode = modes.Game
// export const mode = modes.Recorder
// export const mode = modes.Editor
// export const mode = modes.Story
// export const mode = modes.Heritage
export const position = ref('middle')
export const current_progress  = ref(0, undefined, 'current_progress')
export const next_progress  = ref(0, undefined, 'next_progress')
export const progress_total = ref(word_review_batch_size + number_matches + 1)
export const progress_percent = computed(() => current_progress.value / progress_total.value * 100)

window.onpagehide= (event) => {
    console.log('Page is being hidden');
    // Save state, pause activities, etc.
};

window.onpageshow= (event) => {
    console.log('Page is being shown again');
    // Restore state, resume activities, etc.
};

async function get_meta() {
    const {hash, href} = window.location
    const base = base64_to_object(hash, default_setting)

    $token.value = base.token || localStorage.getItem('token')
    localStorage.setItem("token", $token.value)
    return typeof mode !== "undefined" ? modes[mode] ||  await api.get('/api/meta') : await api.get('/api/meta')
}
export const meta = ref({});
window.meta = meta

export const theme = ref('light')
export const header = ref(false)
export const foreground = ref(false)

export const basket = ref([])
export const batch = ref([])

export const $current_dom = ref(undefined, undefined, '$current_dom')
export const $right_dom = ref(undefined, undefined, '$right_dom')
export const $left_dom = ref(undefined, undefined, '$left_dom')
export const $next_dom = ref(undefined, undefined, '$next_dom')
export const $prev_dom = ref(undefined, undefined, '$prev_dom')
// export const $prev2_dom = ref(undefined, undefined, '$prev2_dom')

export const prev_page_generates = []
export const next_page_generates = []

const max_history = 10
export const prev_doms = []
export const next_doms = []
window.$current_dom = $current_dom
window.$next_dom = $next_dom
window.$prev_dom = $prev_dom
window.prev_page_generates = prev_page_generates
window.next_page_generates = next_page_generates

export async function get_translation(text, language) {
    text = text.trim().toLowerCase()
    const resp = await api.get('/api/translation', {text, language})
    basket.push(resp.data)
    return resp
}
export function push_prev_dom(dom, max_history) {
    if (dom) {
        if (prev_doms.length > max_history) {
            prev_doms.shift()
        }
        prev_doms.push(dom)
    }
}

export function push_next_dom(dom, max_history) {
    if (dom) {
        if (next_doms.length > max_history) {
            next_doms.shift()
        }
        next_doms.push(dom)
    }
}


// innerHeight might exclude the area occupied by the soft keyboard
// while there is NO easy way to get height include soft keyboard
// so the initial_height can be used to have the initialHeight
export const initial_height = ref(Math.max(window.innerHeight, window.innerWidth ))
export const initial_width = ref(Math.min(window.innerHeight, window.innerWidth ))
export const entry_language = ref('swedish')
export let algorithm = ref({})
let sandbox = false;
export const db = IndexedDB("translingo", [
    "vocabulary",
    "basket", // the words get from reading articles or other places
    // "learning",
    "progress",
    "mastered",
    "meta",
    "arabic",
    "english",
    "french",
    "german",
    "japanese",
    "russian",
    "spanish",
    "swedish",
    "cache"
], {sandbox});

const me = {
    algorithm
}

export default me

window.db = db;