import {computed, computed0, on_mounted, after_mounted, watch1, ref} from "nano/reactive.jsx";
import {Render} from "nano/nano.jsx";
import "./App.css";
import "./util.css";
import Show from "nano/Show.jsx"
import UseSwipe from "./UseSwipe.jsx";
import Algorithm from "./Algorithm.js";
import {enable_report, PAGE_DEBUG} from "./config.js";
import {
    $current_dom,
    $left_dom,
    $next_dom,
    $prev_dom,
    $right_dom,
    algorithm,
    basket,
    mode,
    next_doms,
    prev_doms,
    push_next_dom,
    push_prev_dom,
    theme,
    header,
    position, foreground, app, next_page_generates, prev_page_generates,
} from "./store.jsx";
import Signup from "./page/Signup.jsx";
import api from "./api.jsx";
import {toPng} from "html-to-image";
import Dialog from "./dialog/Dialog.jsx";
import IconFrown from "solidjs-feather/IconFrown.jsx";
import Options from "./page/Options.jsx";
import Translator from "./page/Translator.jsx";
import Header from "./components/Header.jsx";
import Heritage from "./heritage/Heritage.jsx";
console.log({type: 'tracer'})

export default function App(props, children) {
    const test = 0;


    const {hash, href} = window.location
    const author = href.includes('author') || localStorage.getItem('author')

    let swipe = UseSwipe({
            onswipeup, onswipeleft, onswiperight, onswipedown, $current_dom
        }
    );

    swipe.directions = {left: true, right: true, up: true, down: true}
    window.swipe = swipe
    const {shiftX, shiftY, scrollShift, shiftYExcludeScroll} = swipe
    const algo = Algorithm({swipe, onswipeup, remove})
    algorithm.value = algo
    window.algo = algo

    function set_enable_report() {
        enable_report.value = true
    }


    async function onswipedown(event, reason) {
        console.log(event)
        if (position.value === 'middle') {
            // still has $prev page
            if ($prev_dom.value) {
                const post_hook = $current_dom.value.post_hook
                const pre_hook = $prev_dom.value.pre_hook

                $next_dom.value = $current_dom.value
                $current_dom.value = $prev_dom.value

                const prev_cached = prev_page_generates.at(-4) // next -1, current -2,  prev: -3, before prev

                // it was previous cached generator
                if (prev_cached) {
                    await prev_cached.generator($prev_dom)
                    const last_cached = prev_page_generates.pop()
                    next_page_generates.push(last_cached)
                    // getting from the next_doms queue, nothing need to be updated
                }
                if (post_hook) {
                    post_hook()
                }
                if (pre_hook) {
                    pre_hook()
                }
            }
        } else {

        }
    }


    async function onswipeup(event, reason) {
        console.log(event)
        if (position.value === 'middle') {
            // still has $next page
            if ($next_dom.value) {
                // only when the history need to be saved, need to
                // update the $prev_dom and save $prev_dom to the history queue
                if ($current_dom?.value?.save_history) {
                    $prev_dom.value = $current_dom.value
                    push_prev_dom($prev_dom.value)
                }

                const post_hook = $current_dom.value.post_hook
                const pre_hook = $next_dom.value.pre_hook

                const dom = $next_dom.value
                dom.style.transition = ``
                dom.style.transform = ``;

                $current_dom.value = $next_dom.value
                $next_dom.value = next_doms.pop()
                const next_cached = next_page_generates.pop()

                // it was previous cached generator
                if (next_cached) {
                    await next_cached.generator($next_dom)
                    prev_page_generates.push(next_cached)
                    // getting from the next_doms queue, nothing need to be updated
                } else {
                    const new_next = await algo.get_generator()
                    const save = await new_next.generator($next_dom)
                    if (save) {
                        prev_page_generates.push(new_next)
                    }
                }
                if (post_hook) {
                    post_hook()
                }
                if (pre_hook) {
                    pre_hook()
                }
            }
        } else {

        }
    }

    async function onswipeleft(event, reason) {
        console.log(event)
        if (position.value === 'middle') {
            // nothing to do when it's already
            // $current_dom.value.post_hook && $current_dom.value.post_hook({})
            // $prev_dom.value = $current_dom.value
            // $current_dom.value = $right_dom.value
            // $current_dom.value.pre_hook && $current_dom.value.pre_hook({})
            // await algo.update_middle($next_dom, $right_dom, $current_dom)
            //
            // position.value = 'left'
            // pointer.directions = {left: true, right: true, up: true, down: true}
            position.value = 'right'
            swipe.directions = {left: false, right: true, up: false, down: false}
        } else if (position.value === 'left') {
            position.value = 'middle'
            // await algo.reupdate_middle()
            swipe.directions = {left: true, right: true, up: true, down: true}
            // pointer.directions = {left: true, right: true, up: true, down: true}
        }
    }

    async function onswiperight(event) {
        console.log(event)
        if (position.value === 'middle') {
            // swap(current, next)
            position.value = 'left'
            // on the left position, there is only one page
            // so only swipe right to change position is needed
            swipe.directions = {left: true, right: false, up: false, down: false}
        } else if (position.value === 'left') {
            // nothing need to do

        }
        else if (position.value === 'right') {
            position.value = 'middle'
            swipe.directions = {left: false, right: true, up: true, down: true}
        }
        else {

        }
    }


    const transform_position = computed(() => {
        switch (position.value) {
            case 'left':
                return `translate(100%, 0)`;
            case 'middle':
                return `translate(0, 0)`;
            case 'right':
                return `translate(-100%, 0)`
            default:
                throw {message: `wrong ${position.value}`}
        }
    })

    const transform_middle = computed(() => {
        const shift = Math.max(shiftY.value + scrollShift.value, 0)
        return `translate(${shiftX.value}px, ${shiftYExcludeScroll.value}px)`
    })

    function remove(event) {
        const dom = $current_dom.value
        dom.style.transition = `transform 0.3s ease-in`
        // dom.style.transform = `scale(0.01) translate(0, calc(100% - 1.5rem))`;
        dom.style.transform = `translate(0, calc(50% - 3rem)) scale(0.01)`;
        setTimeout(async () => {
            // dom.style['z-index'] = '-1'
            await onswipeup(event)
            // setTimeout(() => {
                dom.style.transition = ``
                // dom.style.transform = `unset`;
                dom.style.transform = ``;
            // }, 1000)

        }, 400)
    }

    async function on_login_success(response) {
        console.log('on_login_success')
        meta.value = response
        basket.value = response.basket
        await algo.init()

        // it's the first time, does not need to invoke post_hook
        // $current_dom.value.post_hook && $current_dom.value.post_hook({})

        setTimeout(() => {
            watch1(meta, async (new_value, old_value) => {
                const {left={}, right={}} = new_value
                console.log(meta.value)

                header.value = meta.value.header

                // $right_dom.value = Render(() => Page({}))
                const current =  algo.get_generator()
                await current.generator($current_dom)
                prev_page_generates.push(current)
                const pre_hook= $current_dom.value.pre_hook
                if (pre_hook) {
                     pre_hook()
                }

                const next = algo.get_generator()
                await next.generator($next_dom)
                prev_page_generates.push(next)

                await algo.update($left_dom, left.name)
                await algo.update($right_dom, right.name)
                // $left_dom.value = Render(() => Translator())
            })
        }, 300)

        swipe.use(true)
    }
    async function check_signin() {
        console.log('check_signin')
        const token = localStorage.getItem("token");
        if (token) {
            const message = {
                token: localStorage.getItem("token") || "",
                username: localStorage.getItem("username") || "",
                password_hash: localStorage.getItem("password_hash") || "",
                app: localStorage.getItem("app") || app
            };
            const response = await api.get("/api/login", message);

            await on_login_success(response)
        } else {
            requestAnimationFrame(() => {
                Signup({type: 'Sign up', on_login_success})
            })
        }

    }


    on_mounted(check_signin)
    async function report(event) {

        enable_report.value = false

        const dataUrl = await toPng(document.body);
        try {

            const response = await fetch("/api/report", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({image: dataUrl, data: window.data})
            });

            const dialog = Dialog({},
                <form style="display: flex; flex-direction: column; gap: 15px;">
                    <h1>Thanks</h1>
                    <p>for reporting</p>
                    <p>We will handle it asap</p>
                    <button type="submit" formmethod="dialog">Close</button>
                </form>
            );

            // if (response.ok) {
            //     alert("Image uploaded successfully!");
            // } else {
            //     alert("Failed to upload image");
            // }
        } catch (error) {
            console.error("Error generating or uploading PNG:", error);
        }
    }

    const z_index = computed(() => {

        const result = foreground.value ? 200 : 0
        console.log(result)
        return result
    })
    const app_style = {
        height: "100%",
        "--header-height": computed0([meta], () => meta.value.header ? "64px" : "10px"),
        "--footer-height": "100px"
    }
    return (
        <div style={app_style}>
            <Show when={PAGE_DEBUG}>
                <div class="page_debug">
                    <div id="page_current">current</div>
                    <div id="page_next" style="top: 1rem;">next</div>
                    <div id="page_right">right</div>
                </div>
            </Show>
            <Show when={enable_report}>
                <span onclick={report} class="report" style={{color: computed(() => theme.value === 'dark' ? 'white' : 'black')}}>
                    <IconFrown/>
                </span>
            </Show>
            <Show when={header}>
                <Header shiftX={shiftX}></Header>
            </Show>
            <div id="app" style={{'--percent': test ? '50%': `100%`, transform: transform_position, "z-index": z_index}} data-theme={theme}>

                <div class={{page_container: true, left: true}} _dom={$left_dom}
                     data-name="$left_dom"
                     style={{transform: transform_middle}}></div>

                <div class={{page_container: true, prev: true}} _dom={$prev_dom}
                     data-name="$prev_dom"
                     style={{transform: transform_middle}}></div>
                <div class={{page_container: true, current: true}} _dom={$current_dom}
                     data-name="$current_dom"
                     style={{transform: transform_middle}}></div>
                <div class={{page_container: true, next: true}} _dom={$next_dom}
                     data-name="$next_dom"
                     style={{transform: transform_middle}}></div>

                <div class={{page_container: true, right: true}} _dom={$right_dom}
                     data-name="$right_dom"
                     style={{transform: transform_middle}}></div>
                <div id="orientation">

                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-repeat"><polyline points="17 1 21 5 17 9"/><path d="M3 11V9a4 4 0 0 1 4-4h14"/><polyline points="7 23 3 19 7 15"/><path d="M21 13v2a4 4 0 0 1-4 4H3"/></svg>
                    <div id="orientation_message">ROTATE SCREEN</div>
                </div>
            </div>
        </div>
    )
}