import Options from "../page/Options.jsx";
import "./Word.css";
import {ref_render} from "nano/nano.jsx";
import {ref} from 'nano/reactive.jsx'

import Page from "./WordPage.jsx";
import Prompt from "./WordPrompt.jsx";
import {basket, theme, algorithm, next_progress, current_progress, batch} from "../store.jsx";
import {word_review_batch_size} from "../config.js";
import Summary from "./WordSummary.jsx";

console.log({type: "tracer"})

let word_index = 0
const BATCH_SIZE = 15

export default function WordAlgorithm(props) {
    const {name, show_prompt= false, show_options=false, show_summary= false} = props

    let prompted = false // ? false : true
    let option = !show_options
    let last_page = undefined

    const me = {
        // update,
        // reupdate,
        get_generator,
        finished: false
    }
    const navigate = {
        right: 'ReviewWord',
        next: 'ReviewWord',
    }


    async function reupdate($current_dom, $next_dom) {
        const component = $current_dom.value?.dataset?.component
        if (component === Summary.name) {
            await update($current_dom)
            return true
        } else {
            await update($next_dom)
            return false
        }
    }

    function get_generator() {
        let initial_pre = true
        let initial_post = true
        const word = basket.value[word_index]
        word_index = (word_index + 1)
        batch.push(word)
        me.finished = batch.length % word_review_batch_size === 0

        next_progress.value = next_progress.value + 1
        const progress = next_progress.value

        return {name: word?.text || 'summary', generator: word_generator}
        async function word_generator($dom) {
            function pre_hook(context) {
                theme.value = 'light'

                // algorithm.value.pointer.use({swipe: true})
                algorithm.value.swipe.use(true)

                current_progress.value = progress

                // console.log({type: "pre", text: word?.text, progress})
            }

            function post_hook(context) {
            }

            let save = false
            if (word) {
                const contexts = {
                    props: {word},
                    pre_hook,
                    save_history: true,
                    post_hook,
                    word_index,
                    navigate,
                    total: word_review_batch_size,
                    dataset: {
                        word: word.text,
                        component: 'Word'
                    }
                }
                await ref_render($dom, Page, contexts)
                last_page = 'word'
                save = true
            } else if (last_page === 'summary') {
                // already got summary page, not repeating add again
            } else {
                await ref_render($dom, Summary, {
                    props: {
                        type: 'empty'
                    },
                    pre_hook,
                    save_history: false
                })
                last_page = 'summary'
            }
            return save
        }
    }
    async function update(/* $current_dom, */$dom) {
        return await get_generator()($dom)
        // if (show_prompt && !prompted) {
        //     await ref_render($dom, Prompt, {})
        //     prompted = true
        // }
        // else {
        //     retu
        //     const word = basket.value[word_index]
        //     function pre_hook(context) {
        //         word_index = (word_index + 1)
        //         batch.push(word)
        //
        //         me.finished = batch.length % word_review_batch_size === 0
        //
        //         console.log(batch)
        //
        //         theme.value = 'light'
        //         // algorithm.value.pointer.use({swipe: true})
        //         algorithm.value.swipe.use(true)
        //     }
        //
        //     function post_hook(context) {
        //         current_progress.value = current_progress.value + 1
        //         // word_index = (word_index + 1)
        //         // console.log(word_index)
        //         // me.finished = word_index % word_review_batch_size === 0
        //     }
        //
        //
        //     if (word) {
        //         const contexts = {props: {word},  pre_hook, save_history: true, post_hook, word_index, navigate, total: word_review_batch_size}
        //         await ref_render($dom, Page, contexts)
        //         last_page = 'word'
        //     } else  if (last_page === 'summary') {
        //         // already got summary page, not repeating add again
        //     } else {
        //         await ref_render($dom, Summary, {
        //             props: {
        //                 type: 'empty'
        //             },
        //             pre_hook,
        //             save_history: false
        //         })
        //         last_page = 'summary'
        //     }
        // }

    }
    return me
}
