import IconLogout from "solidjs-feather/IconLogout.jsx";
import IconPause from "solidjs-feather/IconPause.jsx";
import {speak} from "../audio.js";
import Keyboard from "./Keyboard.js";
import Show from 'nano/Show.jsx'

import {after_mounted, computed, ref} from "nano/reactive.jsx";
import {ref_render} from "nano/nano.jsx";
import "./Game.css";
import ShootingWord from "./ShootingWord.jsx";
import {AsyncAudio} from "nano/lib/Async.js";
import {algorithm} from "../store.jsx";


const MINUTE = 1000 * 60
const SECOND = 1000

console.log({type: 'tracer'})

export default async function GamePage(props = {}) {
    const {audio_url, message = "START",
        label = "START",
        name="ShootingWord",
        sequence,
        game_timeout = 1.5 * MINUTE,
        game_over_screen_time = 2 * SECOND
    } = props
    const id = `canvas-${sequence}`

    const Games = {
        ShootingWord,
    }

    let game = undefined
    const Game = Games[name]
    if (!Game) {
        throw {message: `Game ${name} not supported yet`}
    }
    const audio = AsyncAudio("/Prototype.aac");


    let resume_game = undefined

    let playing = ref(true);
    let paused = false
    let frames = 0;
    let started = ref(false)
    const game_over = ref(false)
    const width = window.innerWidth
    const height = window.innerHeight
    const times = {};


    const hide_pause = computed(() => {
        if (started.value) {
            return !playing.value
        } else {
            return true
        }
    })
    const hide_exit = computed(() => !started.value)

    function setup_canvas() {
        const canvas = document.getElementById(id);
        const offscreen_canvas = document.createElement("canvas");

        const devicePixelRatio = window.devicePixelRatio;

        // const aspectRatio = 1024 / 576;
        let prev_size = {width, height};
        // if (window.innerWidth / window.innerHeight > aspectRatio) {
        //     // Window is wider than the aspect ratio
        //     canvas.style.width = `${window.innerHeight * aspectRatio}px`;
        //     canvas.style.height = `${window.innerHeight}px`;
        // } else {
        //     // Window is taller than the aspect ratio
        //     canvas.style.width = `${window.innerWidth}px`;
        //     canvas.style.height = `${window.innerWidth / aspectRatio}px`;
        // }

        canvas.width = width * devicePixelRatio;
        canvas.height = height * devicePixelRatio;
        offscreen_canvas.width = canvas.width;
        offscreen_canvas.height = canvas.height;

        const onscreen_ctx = canvas.getContext("2d");
        onscreen_ctx.scale(devicePixelRatio, devicePixelRatio);

        const offscreen_ctx = offscreen_canvas.getContext("2d");
        offscreen_ctx.scale(devicePixelRatio, devicePixelRatio);

        return {canvas, offscreen_canvas, onscreen_ctx, offscreen_ctx}
    }

    async function start() {
        console.log('start')
        const {canvas, offscreen_canvas, onscreen_ctx, offscreen_ctx} = setup_canvas()
        const keyboard = Keyboard();
        const start_time = Date.now()
        const end_time = start_time + game_timeout
        const $clientX= ref()
            const $clientY= ref()
        game = {
            canvas,
            offscreen_canvas,
            ctx: onscreen_ctx,
            onscreen_ctx,
            offscreen_ctx,
            keyboard: Keyboard(),
            frames: 0,
            $clientX,
            $clientY,
            frame_time: 16,
            audio,
            turn_down_volume() {
                audio.volume = 0.05
            },
            turn_up_volume() {
                audio.volume = 0.5
            },
            prev_time: Date.now(),
            elapsed_time: 0,
        }
        const content = await Game({...props, audio, loop});


        function animate() {
            game.frames += 1;

            let now = Date.now();
            game.frame_time = now - game.prev_time;
            game.prev_time = now;

            game.elapsed_time += game.frame_time
            if (game.elapsed_time  > game_timeout) {
                game_over.value = true
                playing.value = false
                setTimeout(on_exit_game, game_over_screen_time)
                return
            }


            content.clear(game);
            content.update(game);


            content.gc(game);
            content.draw(game);
            game.ctx.drawImage(game.offscreen_canvas, 0, 0, game.offscreen_canvas.width, game.offscreen_canvas.height, 0, 0, innerWidth, innerHeight); //canvas.width, canvas.height);
            if (playing.value) {
                requestAnimationFrame(animate);
            } else {
                console.log('playing value changed, timout/visibility change')
            }
            if (frames % 1000 === 0) {
                // console.log(times);
            }
        }

        function loop(objects, me, fun, name) {
            const start = performance.now();

            for (let i = 0, len = objects.length; i < len; i += 1) {
                const object = objects[i];
                object[fun](game, me);
            }
            times[name] = (times[name] || 0) + performance.now() - start;
        }

        playing.value = true;
        content.init(game)
        swipe.use(false, $clientX, $clientY)
        // pointer.set_receiver(game)
        // pointer.use({swipe: false})
        audio.play();
        animate();

        document.onvisibilitychange = function onvisibilitychange(event)  {
            if (document.hidden) {
                console.log('App is minimized or in the background');
                pause_game()

                // Handle app minimization (e.g., pause tasks, save state)
            } else {
                console.log('App is visible or restored');
                // delay execute let previous event finish when pause game
                setTimeout(resume_game, 500)
                // resume_game()
                // Handle app restoration (e.g., resume tasks)
            }
        };


        return function resume_game() {
            if (playing.value) {
                console.log('ignore, already playing')
            } else {
                audio.play();
                playing.value = true
                paused = false
                animate()
            }
        }
    }

    async function on_exit_game(event) {
        console.log(event)
        event && event.stopPropagation();
        document.onvisibilitychange = undefined
        playing.value = false
        audio.pause();
        // algorithm.value.pointer.use({touch: false, swipe: true})
        algorithm.value.onswipeup(event)
    }


    function pause_game() {
        paused = true
        playing.value = false
        // audio.pause will invoke system api
        // when change to background, after that part will NOT be executed until it become visible
        audio.pause();
    }
    function on_pause(event) {
        event.stopPropagation()
        return pause_game()
    }

    function on_click_canvas(event) {
        // console.log('on_click_canvas', event)
        if (playing.value === false && paused) {
            playing.value = true
            paused = false
            resume_game()
        }
    }

    async function onclick(event) {
        console.log(event);
        await speak(message);
        started.value = true
        const $ignitor = document.getElementById('ignitor')
        $ignitor.remove()
        resume_game = await start();
    }

    // async function game_over(event) {
    //     await on_exit_game(event)
    // }

    const style = {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "width": "100%",
        "font-size": "2rem",
        "z-index": 200,
        // background: `hsla(220, 50%, 90%, 1)`
    };

    function oncontextmenu(event) {
        event.stopPropagation()
        return false
    }

    after_mounted(() => {
        const canvas = document.getElementById(id)
        canvas.oncontextmenu = oncontextmenu
    })

    // start();
    // requestAnimationFrame(start);
    return (
        <div id="app" class="page game flex_center" onclick={on_click_canvas}>
            <canvas id={id} class="canvas undraggable" ></canvas>

            <div class="pause_button" onclick={on_pause} _hide={hide_pause}>
                <IconPause/>
            </div>

            <div class="exit_button" onclick={on_exit_game} >
                <IconLogout/>
            </div>

            <div id="ignitor" class="middle_button" onclick={onclick}>{label}</div>

            <Show when={game_over}>
                <div id="game_over" class="middle_button" onclick={on_exit_game}>Game Over</div>
            </Show>
        </div>
    );
}