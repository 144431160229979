import {get_lang_flag} from "nano/lib/unicode.js";
import For from "nano/For.jsx";
import "./LanguagePicker.css";
import {algorithm, entry_language} from "../store.jsx";

export default function LanguagePicker(props = {}, children=[]) {
    const {languages = ['swedish', 'french']} = props
    console.log(languages)
    function clicker(lang) {
        return function (event) {
            if (entry_language.value === lang) {
                // language setting has not changed
            } else {
                entry_language.value = lang
                // algorithm.value.update()
            }
            algorithm.value.onswipeup(event)
        }
    }
    return (
        <div class="languages" >
            <For _each={languages}>{(lang) => {
                console.log(lang)
                return (
                    <div onclick={clicker(lang)}>
                        <span>{get_lang_flag(lang)} </span>
                        <span style="text-transform: capitalize;">{lang}</span>
                    </div>)
            }}</For>
        </div>
    )
}
